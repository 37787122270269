import React from 'react'
import '../styles/result-box.scss'

export const ResultBox = ({ scoreValue,resultText, fixedNumber = 0.37 }) => (
    <div className="result-box">
        <div className="result-box__left">
            <h2 className="result-heading" style={{textTransform:'inherit'}}>This Patient’s CCP TBI is</h2>
            <h3 className="result-score">{scoreValue}</h3>
            <p className="t-body1 mt-3 mb-3 mb-lg-0">The TBI takes values from 0 to 1. Larger values indicate larger benefit from CCP treatment as compared with treatment without CCP.  The Index can be interpreted according to the table.</p>
        </div>
        <div className="result-box__right">
            <table>
                <thead>
                    <tr>
                        <th>TBI</th>
                        <th>Clinical Interpretation</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{'<0.2'}</td>
                        <td>No expected benefit from CCP</td>
                    </tr>
                    <tr>
                        <td className="text-nowrap">{`>=0.2 and <${fixedNumber}`}</td>
                        <td>Small to moderate benefit from CCP</td>
                    </tr>
                    <tr>
                        <td>{`>=${fixedNumber}`}</td>
                        <td>Substantial benefit from CCP</td>
                    </tr>
                </tbody>
            </table>
            <div className="mt-3 result-addition">
                <p className="t-result-addition">This patient is expected to have {resultText}, compared to the patient's expected  outcome without CCP treatment.</p>
            </div>
        </div>
        
    </div>
)