import React, { useEffect, useState, useCallback, useRef } from 'react';
import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import dataSet from '../assets/csvjson.json';
import CalculatorForm from './calculatorForm';
import '../styles/calculator.scss';
import { ResultBox } from '../components/result-box';
import { Who11PointTable } from '../components/who11-point-table';
import { StatusLegendTable } from '../components/status-legend-table';
import { useWindowSize } from '../hooks/use-window-size';
import { FullSacleTableModal } from '../components/full-scale-table';


const ReactHighcharts = require('react-highcharts'); // Expects that Highcharts was loaded in the code.
const defaultHighchartsConfig = (scoreValue) => ({
    chart: {
        type: 'area',
        backgroundColor: 'transparent',
        height: 290
    },
    credits: {
        enabled: false
    },
    tooltip: { enabled: false },

    title: {
        text: ''
    },
    legend: {
        enabled: false
    },
    xAxis: {
        tickPositioner: function () {
            var positions = [],
                tick = Math.floor(this.dataMin),
                increment = Math.ceil((this.dataMax - this.dataMin) / 5);

            if (this.dataMax !== null && this.dataMin !== null) {
                for (tick; tick - increment <= this.dataMax; tick += increment) {
                    positions.push(tick);
                }
            }
            return positions;
        },
        plotLines: [{
            color: '#FF0000', // Red
            width: 3.6,
            dashStyle: 'Dash',
            zIndex: 9999,
            value: scoreValue * 100, // Position, you'll have to translate this to the values on your x axis
            label: {
                text: scoreValue,
                rotation: 0,
                verticalAlign: 'bottom',
                style: { color: '#fff', fontSize: '16px' },
                y: -10,
                x:   (scoreValue > 0.7)?-40:12,
            }
        }],
        lineWidth: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
            formatter: function () {
                return this.value / 100;
            },
        },
        title: {
            enabled: false
        }
    },
    yAxis: {
        min: 0,
        lineWidth: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        max: 1,
        tickInterval: 0.2,
        title: {
            text: ''
        },
        labels: {
            formatter: function () {
                return this.value;
            }
        }
    },
   
    plotOptions: {
        series: {
            enableMouseTracking: false
        },
        area: {
            stacking: 'normal',
            lineColor: '#666666',
            fillOpacity: 1,
            zIndex: 0,
            lineWidth: 1,
            marker: {
                lineWidth: 1,
                lineColor: '#666666'
            }
        }
    },
    series: [{
        name: 'WHO Scale 0 ~ 3 (non-hospitalized)',
        color: '#FCFCC6',
        data: []
    },
    {
        name: 'WHO Scale 4 ~ 6 (hospitalized)',
        color: '#EAB28B',
        data: []
    },
    {
        name: 'WHO Scale 7 ~ 9 (Ventilated)',
        color: '#5F1EC1',
        data: []
    },
    {
        name: 'WHO Scale 10 (Death)',
        color: '#333333',
        data: []
    }],
    responsive: {
        rules: [{
          condition: {
            minWidth: 500
          },
          chartOptions: {
            chart: {
              height: 300
            },
          }
        }]
      }
});


const defaultTableValue = () => ({
    probability: 0,
    top: 0,
    bottom: 0
});
export default function Calculator() {
    const resultRef = useRef();
    const calculatorRef = useRef();
    const { width } = useWindowSize();
    const [ clickedCalculator, setClickedCalculator ] = useState(false);
    
    const [ openModal, setOpenModal ] = useState(false);
    const handleTooltipClick = useCallback(() => setOpenModal(!openModal), [ openModal ]);
    
    const [resultText, setResultText] = useState(0);

    const [charts, setCharts] = useState([]);
    const [tables, setTables] = useState([[defaultTableValue(), defaultTableValue(), defaultTableValue(), defaultTableValue()],
    [defaultTableValue(), defaultTableValue(), defaultTableValue(), defaultTableValue()],
    [defaultTableValue(), defaultTableValue(), defaultTableValue(), defaultTableValue()],
    [defaultTableValue(), defaultTableValue(), defaultTableValue(), defaultTableValue()],
    ]);


    const [scoreValue, setScoreValue] = useState([]);

    const [calculationForm, setCalculationForm] = useState({
        bloodType: 1,
        age: 55,
        oxygenRequirement: 0,
        heartDisease: 0,
        diabetes: 0,
        pulmonary: 0
    });
    
    const handleEditCalculatorClick = useCallback(() => {
        calculatorRef.current.scrollIntoView();
        setClickedCalculator(false);
    }, [ calculatorRef, clickedCalculator ]);

    const calculate = useCallback((form) => {
        setCalculationForm(form);
        if (width < 992) {
            setClickedCalculator(true);
            resultRef.current.scrollIntoView();
        }
    }, [ width, calculationForm, setCalculationForm, resultRef, clickedCalculator ]);
   
    useEffect(() => {
        let startPoint = 0.36;
        let hasBloodType = false;
        if (parseInt(calculationForm.bloodType) == 0) {
            startPoint = 0.41;
        //    alert('Has no blood type, start point 0.41');
        }
        else {
            hasBloodType = true;
         //   alert('Has blood type, starting point 0.36');

        }
        let who = 6;
        if (parseInt(calculationForm.oxygenRequirement) === 0) {
            who = 4;
        } else if (parseInt(calculationForm.oxygenRequirement) === 1) {
            who = 5;

        }
       // alert('Who is '+who);

        if (who === 5) {
            

            if (hasBloodType) {
              //  alert('Who 5, has bloodtype, '+startPoint+' - 0.24='+(startPoint-0.24));

                startPoint -= 0.24;

                if (parseInt(calculationForm.age) >= 67) {
                   // alert('Age over 66, '+startPoint+' - 0.04 ='+(startPoint-0.04));

                    startPoint -= 0.04;

                }
                else {
                   // alert('Age below 67, '+startPoint+' - 0.24 ='+(startPoint-0.24));

                    // startPoint -= 0.24;


                }
            }
            else {
               // alert('Who 5, has no bloodtype, '+startPoint+' - 0.26='+(startPoint-0.26));

                startPoint -= 0.26;

                if (parseInt(calculationForm.age) >= 67) {
                 //   alert('Age over 66, '+startPoint+' - 0.05 ='+(startPoint-0.05));

                    startPoint -= 0.05;
                }
                else {
                  //  alert('Age below 67, '+startPoint+' - 0.26 ='+(startPoint-0.26));

                    // startPoint -= 0.26;

                }
            }
        }
        if (who === 6) {
            if (hasBloodType) {
              //  alert('Who 6, has bloodtype, '+startPoint+' - 0.17='+(startPoint-0.17));

                startPoint -= 0.17;

                if (parseInt(calculationForm.age) >= 67) {
                 //   alert('Age over 66, '+startPoint+' - 0.19 ='+(startPoint-0.19));

                    startPoint -= 0.19;
                }
                else {
                   // alert('Age below 67, '+startPoint+' - 0.17 ='+(startPoint-0.17));

                 //   startPoint -= 0.17;

                }
            }
            else {
              //  alert('Who 6, has no bloodtype, '+startPoint+' - 0.19='+(startPoint-0.19));

                startPoint -= 0.19;

                if (parseInt(calculationForm.age) >= 67) {
                 //   alert('Age over 66, '+startPoint+' - 0.22 ='+(startPoint-0.22));

                    startPoint -= 0.22;
                }
                else {
                  //  alert('Age below 67, '+startPoint+' - 0.19 ='+(startPoint-0.19));

                    //startPoint -= 0.19;

                }
            }
        }

        if (parseInt(calculationForm.heartDisease) === 1) {
            if (hasBloodType) {
               // alert('Had heart disease and bloodtype, '+startPoint+' + 0.19 ='+(startPoint+0.19));

                startPoint += 0.19;
            }
            else {
              //  alert('Had heart disease and NO bloodtype, '+startPoint+' + 0.23 ='+(startPoint+0.23));

                startPoint += 0.23;
            }

        }
        if (parseInt(calculationForm.diabetes) === 1 && parseInt(calculationForm.pulmonary) === 1) {
            if (hasBloodType) {
             //   alert('Has diabetes and pulmonary and bloodtype, '+startPoint+' + 0.30 ='+(startPoint+0.30));

                startPoint += 0.30;

            }
            else {
              //  alert('Has diabetes and pulmonary and NO bloodtype, '+startPoint+' + 0.36 ='+(startPoint+0.36));

                startPoint += 0.36;
            }


        }
        if (hasBloodType && ((parseInt(calculationForm.bloodType) === 2) || parseInt(calculationForm.bloodType) === 4)) {
           // alert('Blood type is A or AB, '+startPoint+' + 0.14 ='+(startPoint+0.14));

            startPoint += 0.14;

        }


        setScoreValue(Math.round(startPoint * 100) / 100);
        
    }, [calculationForm]);

    useEffect(() => {
        rerenderGraphs();
        if(scoreValue < 0.2){
            setResultText('no expected benefit from treatment with CCP');
        }
        else if(scoreValue < 0.37    ){
            setResultText('small to moderate benefit from CCP');
        }
        else{
            setResultText('substantial benefit from treatment with CCP');
        }
    }, [scoreValue]);

    const rerenderGraphs = () => {
        const t = tables;

        let graphs = [
            defaultHighchartsConfig(scoreValue),
            defaultHighchartsConfig(scoreValue),
            defaultHighchartsConfig(scoreValue),
            defaultHighchartsConfig(scoreValue)
        ]
        dataSet.forEach((row, i) => {
            let graph_i = 0;
            let addRow = false;
            let line = 0;
            if ((row.figure.indexOf('figure.day14.expanded') !== -1 && calculationForm.bloodType !== '0') ||
                (row.figure.indexOf('figure.day14.basic') !== -1 && calculationForm.bloodType === '0') ||
                (row.figure.indexOf('figure.day28.expanded') !== -1 && calculationForm.bloodType !== '0') ||
                (row.figure.indexOf('figure.day28.basic') !== -1 && calculationForm.bloodType === '0')) {
                addRow = true;
            }
            if (row.figure.indexOf('figure.day28') !== -1) {
                graph_i = 2;
            }
            if (addRow) {
                if (row.treatment.indexOf('CCP') === -1) {
                    graph_i++;
                }
                if (row.outcome.indexOf('0~3') !== -1) {
                    line = 0;
                } else if (row.outcome.indexOf('4~6') !== -1) {
                    line = 1;
                } else if (row.outcome.indexOf('7~9') !== -1) {
                    line = 2;
                } else {
                    line = 3;
                }
                if (row.index === scoreValue) {
                    t[graph_i][line].probability = Math.round(row.probability * 100);
                    t[graph_i][line].top = Math.round(row['9750'] * 100);
                    t[graph_i][line].bottom = Math.round(row['25'] * 100);


                }

                graphs[graph_i].series[line].data.push({
                    y: row.probability,
                });
            }

        });
        setTables(t);
        setCharts(graphs);


    };
    return <Layout>
        <div className="calculator" ref={calculatorRef}>
            <Sidebar>
                <CalculatorForm calculate={calculate}></CalculatorForm>
            </Sidebar>
            <div className="result-page" ref={resultRef}>
                {clickedCalculator && (
                    <button className="edit-calculator-mobile-btn" type="button" onClick={handleEditCalculatorClick}>
                        <span>Edit Calculator</span>
                        <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.40383 2.16345C0.0576772 1.8173 0.0576772 1.29807 0.40383 1.03845L1.18268 0.259614C1.52883 -0.0865388 2.04806 -0.0865388 2.30767 0.259614L7.49997 5.45191L12.6923 0.259615C13.0384 -0.0865383 13.5576 -0.0865383 13.8173 0.259615L14.5961 1.03845C14.9423 1.38461 14.9423 1.90384 14.5961 2.16345L8.01919 8.74036C7.67304 9.08652 7.15381 9.08652 6.8942 8.74036L0.40383 2.16345Z" fill="#05316F"/>
                        </svg>
                    </button>
                )}
                <div className="container container--y-only">
                    <div className="container container--x-only container--no-max">
                        <h1 className="t-heading1 mb-3">Convalescent Plasma Benefit Index Calculator Results</h1>
                        <ResultBox scoreValue={scoreValue} resultText={resultText} fixedNumber={calculationForm.bloodType === '0' ? 0.4 : 0.37} />
                        <p className="t-body1 mt-5 mb-3">Below the benefit is explained in terms of the expected patient's status, under treatment with CCP and under treatment without CCP, at day 14 and day 28 from baseline.</p>
                        <div>
                            <h4 className="t-heading3 mt-3 mt-lg-4 mb mb-3">Day 14 Status</h4>
                            <h5 className="t-heading4 mt-3 d-lg-none">Expected patient's status at day 14 with CCP and without CCP treatment</h5>
                        </div>
                    </div>
                    <div className="result-container pt-3">
                        {charts.length > 0 &&
                            <div className="result-row">
                                <div className="container container--blue container--x-only container--no-max">
                                    <h5 className="t-heading4 mb-3 d-none d-lg-block">Expected patient's status at day 14 with CCP and without CCP treatment</h5>
                                    <div className="graph-box">
                                        <div className="graph-row">
                                            <div className="category-title category-title--desktop">
                                                <label className="t-graph-label"> Probability of each category</label>
                                            </div>
                                            <div className="graphs">
                                                <div className="graph-container mb-4 mb-lg-0">
                                                    <div className="category-title category-title--mobile">
                                                        <label className="t-graph-label"> Probability of each category</label>
                                                    </div>
                                                    <div className="graph-wrap">
                                                        <span className="graph-label graph-label--top t-graph-label">Treatment  =  With CCP</span>
                                                        <ReactHighcharts config={charts[0]}></ReactHighcharts>
                                                        <div className="graph-row-center t-graph-label graph-label--bottom-mobile">CCP Treatment Benefit Index</div>
                                                    </div>
                                                    
                                                </div>
                                                <div className="graph-container">
                                                    <div className="category-title category-title--mobile">
                                                        <label className="t-graph-label"> Probability of each category</label>
                                                    </div>
                                                    <div className="graph-wrap">
                                                        <span className="graph-label graph-label--top t-graph-label">Treatment  =  Without CCP</span>
                                                        <ReactHighcharts config={charts[1]}></ReactHighcharts>
                                                        <div className="graph-row-center t-graph-label graph-label--bottom-mobile">CCP Treatment Benefit Index</div>
                                                    </div>
                                                </div>
                                                <div className="graph-row-center t-graph-label graph-label--bottom-desktop mt-2 mt-lg-3">CCP Treatment Benefit Index</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="result-legend">
                            <div className="container container--blue container--x-only container--no-max pt-4 pt-lg-0">
                                <StatusLegendTable />
                                <div className="divider py-3"></div>
                            </div>
                        </div>
                        <p className="t-body1 result-detail">
                            <div className="container container--x-only container--no-max">
                                The plots show the probability of the patient's status at day 14 under treatment with CCP <span className="d-none d-lg-inline">(left)</span><span className="d-lg-none">(top)</span> and under treatment without CCP <span className="d-none d-lg-inline">(right)</span><span className="d-lg-none">(bottom)</span>, as it depends on the treatment benefit index. The color coding of the status corresponds to the <button className="inline-btn" type="button" onClick={handleTooltipClick}>WHO 11-point Scale</button> as indicated in the legend above. This patient's score on the CCP TBI ({scoreValue}) is marked with a vertical red dashed line. 
                            </div>
                        </p>
                    </div>
                <div className="container container--x-only container--no-max">
                    <div className="divider py-3"></div>
                </div>
                <div>
                    <div className="container container--x-only container--no-max">
                        <p className="t-body1 mb-3">For a patient with TBI={scoreValue}, the table below gives the probabilities and 95% confidence intervals for the expected status under treatment with CCP and under treatment without CCP.</p>
                        {tables.length > 0 &&
                            <Who11PointTable withCcp={tables[0]} withoutCcp={tables[1]} day={14} />
                        }
                    </div>
                    </div>
                    <div className="container container--x-only container--no-max">
                        <div className="divider py-4 py-lg-5"></div>
                    </div>
                    <div className="container container--x-only container--no-max">
                        <h4 className="t-heading3 mb-4">Day 28 Status</h4>
                        <h5 className="t-heading4 d-lg-none">Expected patient's status at day 28 with CCP and without CCP treatment</h5>
                    </div>
                    <div className="result-container pt-3">
                    {charts.length > 0 &&
                        <div className="result-row">
                            <div className="container container--blue container--x-only container--no-max">
                                <h5 className="t-heading4 mb-3 d-none d-lg-block">Expected patient's status at day 28 with CCP and without CCP treatment</h5>
                                <div className="graph-box">
                                    <div className="graph-row">
                                        <div className="category-title category-title--desktop">
                                            <label className="t-graph-label"> Probability of each category</label>
                                        </div>
                                        <div className="graphs">
                                            <div className="graph-container mb-4 mb-lg-0">
                                                <div className="category-title category-title--mobile">
                                                    <label className="t-graph-label"> Probability of each category</label>
                                                </div>
                                                <div className="graph-wrap">
                                                    <span className="graph-label graph-label--top t-graph-label">Treatment  =  With CCP</span>
                                                    <ReactHighcharts config={charts[2]}></ReactHighcharts>
                                                    <div className="graph-row-center t-graph-label graph-label--bottom-mobile">CCP Treatment Benefit Index</div>
                                                </div>
                                                
                                            </div>
                                            <div className="graph-container">
                                                <div className="category-title category-title--mobile">
                                                    <label className="t-graph-label"> Probability of each category</label>
                                                </div>
                                                <div className="graph-wrap">
                                                    <span className="graph-label graph-label--top t-graph-label">Treatment  =  Without CCP</span>
                                                    <ReactHighcharts config={charts[3]}></ReactHighcharts>
                                                    <div className="graph-row-center t-graph-label graph-label--bottom-mobile">CCP Treatment Benefit Index</div>
                                                </div>
                                            </div>
                                            <div className="graph-row-center t-graph-label graph-label--bottom-desktop mt-2 mt-lg-3">CCP Treatment Benefit Index</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="result-legend">
                        <div className="container container--blue container--x-only container--no-max pt-4 pt-lg-0">
                            <StatusLegendTable statusDay={28} />
                            <div className="divider py-3"></div>
                        </div>
                    </div>
                    <FullSacleTableModal openModal={openModal} handleTooltipClick={handleTooltipClick} />
                    <div className="container container--x-only container--no-max">
                        <p className="t-body1 result-detail">The plots show the probability of the patient's status at day 28 under treatment with CCP <span className="d-none d-lg-inline">(left)</span><span className="d-lg-none">(top)</span> and under treatment without CCP <span className="d-none d-lg-inline">(right)</span><span className="d-lg-none">(bottom)</span>, as it depends on the treatment benefit index. The color coding of the status corresponds to the <button className="inline-btn" type="button" onClick={handleTooltipClick}>WHO 11-point Scale</button> as indicated in the legend above. This patient's score on the CCP TBI ({scoreValue}) is marked with a vertical red dashed line. </p>
                    </div>
                    </div>
                    <div className="container container--x-only container--no-max">
                        <div className="divider py-3 py-lg-4"></div>
                    </div>
                    <div className="container container--x-only container--no-max">
                    <div className="mb-3">
                        The table below gives the probabilities and 95% confidence intervals for the expected outcome when TBI={scoreValue} under treatment with CCP and under treatment without CCP.
                    </div>
                    {tables.length > 0 &&
                        <Who11PointTable withCcp={tables[2]} withoutCcp={tables[3]} day={28} />
                    }

                    <div className="divider py-3 py-lg-5"></div>
                    <h5 className="t-heading3 mb-4">How is CCP TBI score calculated?</h5>
                    <div className="info-boxes">
                        <div className="info-box">
                            <h5 className="t-heading4 mb-3 mb-lg-4">When the patient’s blood type is available: </h5>
                            <table className="how-calculated-table">
                                <tbody>
                                    <tr>
                                        <td>Start with 0.36.</td>
                                    </tr>
                                    <tr>
                                        <td>If the patient’s <button className="inline-btn" type="button" onClick={handleTooltipClick}>WHO scale</button> =5, then subtract 0.24 from the score. If the patient’s WHO scale =5 and age >= 67, then further subtract 0.04 from the score. </td>
                                    </tr>
                                    <tr>
                                        <td>If the patient WHO scale =6, then subtract 0.17 from the score. If the patient’s WHO scale =6 and age >= 67, then further subtract 0.19 from the score.</td>
                                    </tr>
                                    <tr>
                                        <td>If the patient has a history of heart disease, then add 0.19.</td>
                                    </tr>
                                    <tr>
                                        <td>If the patient has both a history of diabetes and a history of pulmonary disease, then add 0.30.</td>
                                    </tr>
                                    <tr>
                                        <td>If the patient’s blood type is A or AB, then add 0.14.</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                        <div className="info-box">
                            <h5 className="t-heading4 mb-3 mb-lg-4">When the patient’s blood  type is not available:</h5>
                            <table className="how-calculated-table">
                                <tbody>
                                    <tr>
                                        <td>Start with 0.41.</td>
                                    </tr>
                                    <tr>
                                        <td>If the patient’s WHO scale =5, then subtract 0.26 from the score. If the patient’s WHO scale =5 and age >= 67, then further subtract 0.05 from the score.</td>
                                    </tr>
                                    <tr>
                                        <td>If the patient WHO scale =6, then subtract 0.19 from the score. If the patient’s WHO scale =6 and age >= 67, then further subtract 0.22 from the score.</td>
                                    </tr>
                                    <tr>
                                        <td>If the patient has a history of heart disease, then add 0.23.</td>
                                    </tr>
                                    <tr>
                                        <td>If the patient has both a history of diabetes and a history of pulmonary disease, then add 0.36.</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                    </div>
            </div>
        </div>
        </div>
    </Layout>
}