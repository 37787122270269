import React from "react"
import '@fontsource/crimson-pro'
import '@fontsource/crimson-pro/900.css'
import '@fontsource/libre-franklin'
import '@fontsource/libre-franklin/600.css'
import '@fontsource/libre-franklin/700.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import "./layout.scss"

import "../styles/global.scss"
import { Link } from "gatsby"
import logo from '../images/logo.svg';
import footerLogo from '../images/footer-logo.svg';

export default function Layout({ children }) {
    return <div className="layout">
                <header className='header'>
                    <nav>
                        <Link to="/"> <img className="logo" src={logo} /></Link>
                        <Link to="/calculator" className="btn primary btn-nav-calculator">Benefit Calculator</Link>
                    </nav>
                </header>
                <main className="animationIn">
                    {children}
                </main>
                <footer className="footer">
                    <div className="container-lg">
                        <nav>
                            <div className="footer-logo-container">
                                <Link to="/" className="footer-logo-wrap"> <img className="footer-logo" src={footerLogo} /></Link>
                            </div>
                            <div className="footer-menu">
                                <Link to="/"> Home</Link>
                                <Link to="/calculator"> Calculator</Link>
                                <div className="vertical-divider"></div>
                                <Link className="legal-and-disclaimer" to="/legal-and-disclaimer">Legal and Disclaimer</Link>
                            </div>
                            <a className="copyright">© 2021 COMPILE</a>
                        </nav>
                    </div>                   
                </footer>
    </div>
}