import { useEffect, useState, useRef } from 'react'
export const useWindowSize = () => {
    const timerRef = useRef()

    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    })
    useEffect(() => {
      let isMounted = true
      const handleResize = () => {
        if (isMounted) {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          })
        }
      }
      window.addEventListener("resize", () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current) 
        }
        timerRef.current = setTimeout(handleResize, 250)
      })
      handleResize()

      return () => {
        window.removeEventListener("resize", handleResize)
        isMounted = false
      }
    }, [])

    return windowSize
  }