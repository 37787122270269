import React from 'react'
import '../styles/who11-point-table.scss'
import { useWindowSize } from '../hooks/use-window-size'

export const Who11PointTable = ({withCcp,withoutCcp,day =14}) => {
    const size = useWindowSize()
    console.log(withCcp,'werwerwe');
    if (size.width && size.width > 992) {
        return (
            <table className="who11-point-table animationIn">
            <thead>
                <tr>
                    <th rowSpan="2">Patient's status at day {day}</th>
                    <th colSpan="2">Expected probability of clinical status (95% Confidence interval) under treatment</th>
                </tr>
                <tr>
                    <th>With CCP</th>
                    <th>Without CCP</th>
                </tr>
            </thead>
            <tbody>
            <tr>
                                    <td>Uninfected, asymptomatic, or symptomatic, but non-hospitalized (WHO Scale 0-3)</td>
                                    <td>{withCcp[0].probability}% ({withCcp[0].bottom}%, {withCcp[0].top}%)</td>
                                    <td>{withoutCcp[0].probability}% ({withoutCcp[0].bottom}%, {withoutCcp[0].top}%)</td>
                                </tr>
                                <tr>
                                    <td>Hospitalized, no oxygen therapy, or oxygen without intubation (WHO Scale 4-6)</td>
                                    <td>{withCcp[1].probability}% ({withCcp[1].bottom}%, {withCcp[1].top}%)</td>
                                    <td>{withoutCcp[1].probability}% ({withoutCcp[1].bottom}%, {withoutCcp[1].top}%)</td>
                                </tr>
                                <tr>
                                    <td>Intubation and mechanical ventilation (WHO Scale 7-9)</td>
                                    <td>{withCcp[2].probability}% ({withCcp[2].bottom}%, {withCcp[2].top}%)</td>
                                    <td>{withoutCcp[2].probability}% ({withoutCcp[2].bottom}%, {withoutCcp[2].top}%)</td>
                                </tr>
                                <tr>
                                    <td>Death (WHO Scale 10)</td>
                                    <td>{withCcp[3].probability}% ({withCcp[3].bottom}%, {withCcp[3].top}%)</td>
                                    <td>{withoutCcp[3].probability}% ({withoutCcp[3].bottom}%, {withoutCcp[3].top}%)</td>
                                </tr>
            </tbody>
        </table>
        )
    }
    return (
        <div className="who11-point-tables animationIn">
            <h5 className="d-lg-none mb-2 t-heading4 mobile-heading">Expected Patient Status at Day {day}</h5>
            <table className="who11-point-table mb-3">
                <thead>
                    <tr>
                        <th colSpan="2">Under Treatment With CCP</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Not hospitalized</td>
                        <td>{withCcp[0].probability}% ({withCcp[0].bottom}%, {withCcp[0].top}%)</td>
                    </tr>
                    <tr>
                        <td>Hospitalized</td>
                        <td>{withCcp[1].probability}% ({withCcp[1].bottom}%, {withCcp[1].top}%)</td>
                    </tr>
                    <tr>
                        <td>Mechanically Ventilated</td>
                        <td>{withCcp[2].probability}% ({withCcp[2].bottom}%, {withCcp[2].top}%)</td>
                    </tr>
                    <tr>
                        <td>Death</td>
                        <td>{withCcp[3].probability}% ({withCcp[3].bottom}%, {withCcp[3].top}%)</td>
                    </tr>
                </tbody>
            </table>
            <table className="who11-point-table">
                <thead>
                    <tr>
                        <th colSpan="2">Under Treatment Without CCP</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Not hospitalized</td>
                        <td>{withoutCcp[0].probability}% ({withoutCcp[0].bottom}%, {withoutCcp[0].top}%)</td>
                    </tr>
                    <tr>
                        <td>Hospitalized</td>
                        <td>{withoutCcp[1].probability}% ({withoutCcp[1].bottom}%, {withoutCcp[1].top}%)</td>
                    </tr>
                    <tr>
                        <td>Mechanically Ventilated</td>
                        <td>{withoutCcp[2].probability}% ({withoutCcp[2].bottom}%, {withoutCcp[2].top}%)</td>
                    </tr>
                    <tr>
                        <td>Death</td>
                        <td>{withoutCcp[3].probability}% ({withoutCcp[3].bottom}%, {withoutCcp[3].top}%)</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}