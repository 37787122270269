import React from 'react'
import '../styles/status-legend-table.scss'
import { LabelTooltip } from './label-tooltip'

export const StatusLegendTable = ({
    statusDay = 14,
}) => (
    <>
    <div className="status-legend-table">
        <h3 className="mb-2 legend-heading">Patient's status at day {statusDay}</h3>
        <p className="mb-2">Non-hospitalized (WHO Scale 0-3)</p>
        <p className="mb-2">Hospitalized (WHO Scale 4-6)</p>
        <p className="mb-2">Mechanically ventilated (WHO Scale 7-9)</p>
        <p>Death (WHO Scale 10)</p>
        <label className="legend-tooltip mt-3 d-none d-lg-flex">
            <LabelTooltip isFullScaleTable={true} /> See full WHO 11-point COVID-19 patient status scale
        </label>
    </div>
    </>
)